
import PricingForm from '@razlet/sdk/lib/components/asb/pricing/form';
import search from '~/mixins/search';
import suluUpdate from '~/mixins/sulu-update';
import { getSuluData, getSuluApi, handleResponse } from '~/utils/sulu';
import NewsList from '~/entities/news-list';
import Banners from '~/entities/banners-list';
import tree from '~/assets/img/tree.png';

const fiveMinutes = 60 * 5;
const weekInSeconds = 60 * 60 * 24 * 7;

function setBannersIndex(app, index = 0) {
  app.$cookiz.set('current-banner-index', index, { path: '/', maxAge: weekInSeconds });
};

export default {
  name: 'index-page',
  layout: 'default',
  components: {
    PricingForm,
  },
  mixins: [search, suluUpdate],
  async asyncData ({ store, error, redirect, app }) {
    if (!/\/\/testapi.razlet.ru/.test(process.env.asb3URL)) {
      const bannersIndex = app.$cookiz.get('current-banner-index');

      const results = await Promise.allSettled([
        getSuluData({ store, error, redirect, path: `news-list` }),
        getSuluApi({ store, error, redirect, path: `snippet-areas/gallery` }),
      ]);

      const [news, banners] = handleResponse(results, error);
      return { news, banners, bannersIndex };
    }
    return { news: {}, banners: {}, bannersIndex: null };
  },
  data() {
    return {
      news: {},
      banners: {},
      bannersIndex: null,
      timer: null,
      tree,
    };
  },
  created() {
    if (!this.bannersIndex && !this.isDev) {
      this.bannersIndex = 0;
      setBannersIndex(this, 0);
    }
    this.formatSuluData();
  },
  mounted() {
    this.$bus.$on('locale-changed', () => {
      this.$nuxt.refresh().then(() => {
        this.formatSuluData();
      });
    });
    setTimeout(() => {
      setBannersIndex(this, this.bannersIndex + 1);
    }, fiveMinutes * 999);
  },
  destroyed() {
    this.$bus.$off('locale-changed');
  },
  methods: {
    formatSuluData() {
      this.news = new NewsList(this.news);
      this.banners = new Banners(this.banners, this.bannersIndex);
    },
  },
};
