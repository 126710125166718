
import GateNotificationTooltip from '@razlet/sdk/lib/components/office/notification/tooltip';
import { mapGetters } from 'vuex';
import { USER } from '@razlet/sdk/lib/constants/roles';
import { BALANCE, CREDIT, MOTIVATION } from '@razlet/sdk/lib/store/auth/getters.type';
import BalanceCard from '@razlet/sdk/lib/components/asb/user/balance';
import ContractsModal from '@razlet/sdk/lib/components/asb/auth/contracts-modal';
import AuthModal from '@razlet/sdk/lib/components/asb/auth/modal';

export default {
  name: 'login-menu',
  components: {
    BalanceCard,
    ContractsModal,
    AuthModal,
    GateNotificationTooltip,
  },
  data() {
    return {
      isModal: false,
      isMenuShowed: false,
      initialLoading: true,
      isSwitchContractModal: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'loading']),
    ...mapGetters('auth', [BALANCE, CREDIT, MOTIVATION]),
    profileMenu() {
      return [
        { label: this.$t('menu.documents'), href: `https://gate.razlet.ru/docs0/user-${this.user.model.generalId}/contract-${this.user.model.contractId}/year-2022/?login=${this.user.model.email}`, access: USER },
        { label: this.$t('menu.profile'), route: 'account-profile', access: USER },
        { label: this.$t('menu.orders'), route: 'account-orders', access: USER },
        { label: this.$t('menu.operations'), route: 'account-operations', access: USER },
        { label: this.$t('menu.bonuses'), route: 'account-bonuses', access: USER },
        { label: this.$t('menu.requests'), route: 'account-requests', access: USER },
        { label: this.$t('menu.tickets'), route: 'account-tickets', access: USER },
        { label: this.$t('menu.passengers'), route: 'account-passengers', access: USER },
        /* { label: this.$t('menu.lottery'), route: 'account-lottery', access: USER }, */
      ];
    },
    isLoading() {
      if (typeof window === 'undefined') {
        return true;
      }
      return this.loading || typeof this.user === 'undefined';
    },
    userName() {
      const { user } = this;
      if (typeof user === 'undefined' || user.token === null) {
        return '';
      }
      return `${user.model.surname} ${user.model.name}`;
    },
    filteredProfileMenu() {
      if (!this.isUser || !this.user.model.roles) return this.profileMenu;
      return this.profileMenu
        .filter(item => this.user.model.roles.includes(item.access))
        .filter(item => item.generals ? item.generals.includes(this.user.model.generalId) : true);
    },
  },
  watch: {
    '$route': {
      handler() {
        this.isMenuShowed = false;
      },
      deep: true,
    },
  },
  methods: {
    logout() {
      this.$bus.$emit('logout');
      this.$bus.$emit('clear-notifications');
      this.isMenuShowed = false;
    },
    outSideClick() {
      if (this.isMenuShowed) {
        this.isMenuShowed=false;
      }
    },
  },
};
