
export default {
  name: 'new-year',
  computed: {
    nextYear() {
      const today = new Date();
      return today.getFullYear() + 1;
    },
  },
};
