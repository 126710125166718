export default {
  methods: {
    errorSearch(errors) {
      if (errors.constructor === Array) {
        errors.forEach(error => {
          this.addNotification(error);
        });
      }
      else this.addNotification(errors);
    },
    addNotification(error) {
      this.$bus.$emit('add-removable-notification', { theme: 'error', text: error, seconds: 5 });
    },
    successSearch(uid, search) {
      this.$router.push({
        name: 'search-uid',
        params: { uid: uid },
        query: { search },
      });
    },
  },
};