import { render, staticRenderFns } from "./footer-block.vue?vue&type=template&id=36206a56"
import script from "./footer-block.vue?vue&type=script&lang=js"
export * from "./footer-block.vue?vue&type=script&lang=js"
import style0 from "./footer-block.vue?vue&type=style&index=0&id=36206a56&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconPhoneCircle: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-phone-circle/index.vue').default,RzIconWhatsApp: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-whats-app/index.vue').default,RzIconTelegram: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-telegram/index.vue').default,RzIconMail: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-mail/index.vue').default,RzIconClockOutline: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-clock-outline/index.vue').default,RzIconAssistant: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-assistant/index.vue').default,RzIconVisa: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-visa/index.vue').default,RzIconElcard: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-elcard/index.vue').default,RzIconDemir: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-demir/index.vue').default,RzIconMastercard: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-mastercard/index.vue').default,RzIconMaestro: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-maestro/index.vue').default,RzIconMbank: require('/opt/razlet.kg/node_modules/@razlet/ui/src/icons/icon-mbank/index.vue').default})
