import Vue from 'vue';
import * as mutations from './mutations.types';

export default {
  [mutations.START_LOADING]: (state) => {
    if (Vue.prototype.$bus) Vue.prototype.$bus.$emit('refresh-loading', true);
    Vue.set(state, "loading", true);
  },
  [mutations.STOP_LOADING]: (state) => {
    if (Vue.prototype.$bus) Vue.prototype.$bus.$emit('refresh-loading', false);
    Vue.set(state, "loading", false);
  },
  [mutations.SET_LOADING]: (state, value) => {
    if (Vue.prototype.$bus) Vue.prototype.$bus.$emit('refresh-loading', value);
    Vue.set(state, "loading", value);
  },
  [mutations.START_LOADING_NO_EVENT]: (state) => {
    Vue.set(state, "loading", true);
  },
  [mutations.STOP_LOADING_NO_EVENT]: (state) => {
    Vue.set(state, "loading", false);
  },
  [mutations.SET_LOADING_NO_EVENT]: (state, value) => {
    Vue.set(state, "loading", value);
  },
  [mutations.LOGIN_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
    Vue.set(state, "refreshError", null);
  },
  [mutations.LOGIN_SUCCESS]: (state, user) => {
    Vue.set(state, "model", user);
    Vue.set(state, "loading", false);
  },
  [mutations.LOGIN_CONTRACTS]: (state, contracts) => {
    Vue.set(state, "contracts", contracts);
    Vue.set(state, "loading", false);
  },
  [mutations.LOGIN_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "error", error);
  },
  [mutations.REFRESH_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
    Vue.set(state, "refreshError", null);
  },
  [mutations.TOKEN_SUCCESS]: (state, token) => {
    if (Vue.prototype.$bus) Vue.prototype.$bus.$emit('token-set', {old: state.token, new: token});
    Vue.set(state, "token", token);
  },
  [mutations.TOKEN_CLEAR]: (state) => {
    if (Vue.prototype.$bus) Vue.prototype.$bus.$emit('token-clear', state.token);
    Vue.set(state, "token", null);
  },
  [mutations.USER_SUCCESS]: (state, user) => {
    Vue.set(state, "model", {...user, roles: state.model?.roles || [] });
  },
  [mutations.USER_CLEAR]: (state) => {
    Vue.set(state, "model", {});
  },
  [mutations.TOKEN_SUCCESS_NO_EVENT]: (state, token) => {
    Vue.set(state, "token", token);
  },
  [mutations.TOKEN_CLEAR_NO_EVENT]: (state) => {
    Vue.set(state, "token", null);
  },
  [mutations.REFRESH_SUCCESS]: (state, user) => {
    Vue.set(state, "model", {...user, roles: state.model?.roles || [] });
    Vue.set(state, "loading", false);
    Vue.set(state, "refreshError", null);
  },
  [mutations.REFRESH_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "refreshError", error);
  },
  [mutations.LOGOUT_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
    Vue.set(state, "model", {});
    Vue.set(state, "contracts", null);
  },
  [mutations.LOGOUT_SUCCESS]: (state) => {
    Vue.set(state, "loading", false);
  },
  [mutations.LOGOUT_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "error", error);
  },
  [mutations.CONTRACTS_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
  },
  [mutations.CONTRACTS_SUCCESS]: (state) => {
    Vue.set(state, "loading", false);
  },
  [mutations.CONTRACTS_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "error", error);
  },
  [mutations.SET_NATIVE_REFRESH_TOKEN]: (state, token) => {
    Vue.prototype.$setNativeRefreshToken(token);
  },
  [mutations.CLEAR_NATIVE_REFRESH_TOKEN]: (state) => {
    Vue.prototype.$removeNativeRefreshToken();
  },
};
