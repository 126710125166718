/* eslint-disable no-undef */
import Vue from 'vue';

export default () => {
  if (!Vue.prototype.$ym) {
    const key = process.env.ymSiteId;
    Vue.prototype.$ym = {
      setUser: function(user) {
        if (key && typeof ym === 'function') {
          ym(key, 'userParams', {
            UserID: user.id,
            contract: user.contractId,
            contractor: user.contractor?.id,
          });
        }
      },
      emit: function(event, params) {
        if (key && typeof ym === 'function') {
          ym(key, 'reachGoal', event, params);
        }
      },
    };
  }
};