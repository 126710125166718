export function fillSeo (data) {
  return {
    title: data.title && data.title !== 'title'
      ? `${data.title} | Razlet.KG`
      : `Razlet.KG`,
    meta: [
      ...  data.description && data.description.length
        ? [{
          hid: 'description',
          name: 'description',
          content: data.description,
        }]
        : [],
      ...  data.keywords && data.keywords.length
        ? [{
          hid: 'keywords',
          name: 'keywords',
          content: data.keywords,
        }]
        : [],
    ],
  };
};

export function handleError(error, redirect, e) {
  if (e.status === 301) redirect(e.url);
  else if (e.status === 404) error(e);
  // eslint-disable-next-line no-console
  else console.error(e.status, e.message);
}

export async function getSuluData({ path, store, error, redirect }) {
  const result = await store.dispatch('sulu/getData', { path:`${path}` }).catch(e => handleError(error, redirect, e));
  return result;
};

export async function getSuluApi({ path, store, error, redirect }) {
  const result = await store.dispatch('sulu/getApiData', { path:`${path}` }).catch(e => handleError(error, redirect, e));
  return result;
};

export async function getSuluPage({ store, route, error, redirect }) {
  const { path } = route;
  const result = await getSuluData({ path, store, error, redirect });
  return result ? { template: result.template, content: result.content, seo: result.extension.seo } : { template: null, content: {}, seo: {} };
};

export function handleResponse(results, error) {
  const values = [];
  results.forEach(res => {
    if (res.status === 'rejected') {
      const err = res.reason;
      error(err);
    } else {
      values.push(res.value);
    }
  });

  return values;
}
