export default {
  mounted () {
    this.$bus.$on('update-sulu-auth', async (value) => {
      if (!this.isApp) return;
      this.setSuluAuth(value);
      this.updateSuluData();
    });
    this.$bus.$on('locale-changed', async () => {
      this.updateSuluData();
    });
  },

  beforeDestroy () {
    this.$bus.$off('update-sulu-auth');
    this.$bus.$off('locale-changed');
  },

  methods: {
    setSuluAuth (value = false) {
      this.$store.commit('sulu/setValue', { key: 'auth', value });
    },
    async updateSuluData() {
      await this.$nuxt.refresh();
      this.formatSuluData();
    },
  },
};
