import Vue from 'vue';
import { mapGetters } from 'vuex';
import filters from '@razlet/sdk/lib/filters/base';
import { MOTIVATION_CONTRACT, BALANCE } from '@razlet/sdk/lib/store/auth/getters.type';
import { isNotNull, isFalsy, isNumber, isString, isObject, isValidObject, isArray, isValidArray } from '@razlet/sdk/lib/utils/helpers';

const PLATINUM_MOTIVATION_CONTRACT_IDS = [];
const GOLD_MOTIVATION_CONTRACT_IDS = [2495, 3560, 3125, 3399, 3305, 6403, 9678];

Vue.mixin({
  data() {
    return {
      isBonuses: true,
      isKg: true,
      kgPhone: '+996 (709) 63-33-33',
      globalMountKey: 1,
      managersContacts: [
        // { name: 'Чолпон', phone: '+996 (702) 11-18-50', href: '996702111850' },
        { name: 'Назбийке', phone: '+996 (706) 11-18-50', href: '996706111850' },
        { name: 'Мираида', phone: '+996 (708) 11-18-50', href: '996708111850' },
        { name: 'Асема', phone: '+996 (709) 11-18-50', href: '996709111850' },
        { name: 'Нурайым', phone: '+996 (707) 11-18-50', href: '996707111850' },
      ],
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isOffline', 'isInitialOffline', 'user', 'currentTheme']),
    ...mapGetters('auth', [BALANCE, MOTIVATION_CONTRACT]),
    isDev() {
      return /\/\/testapi.razlet.ru/.test(process.env.asb3URL);
    },
    isPublic() {
      return process.env.type === 'public' || (this.isApp && !this.isUser);
    },
    isApp() {
      return process.env.type === 'app';
    },
    isAgent() {
      return process.env.type === 'agent' || (this.isApp && this.isUser);
    },
    isAgentSite() {
      return false;
    },
    isSulu() {
      return process.env.type === 'sulu';
    },
    showLogin() {
      return process.env.auth !== 'none';
    },
    isUser() {
      return typeof this.$store.state.user !== 'undefined' && this.user.model && !!Object.keys(this.user.model).length;
    },
    isRolesLoaded() {
      return !!(this.user?.model?.roles?.length);
    },
    userRoles() {
      if (!this.isRolesLoaded) return [];
      return this.user.model.roles;
    },
    currency() {
      return process.env.currency || '';
    },
    suluUrl() {
      return process.env.suluURL;
    },
    userCurrency() {
      return this.isUser ? this.user.model.currency : this.currency;
    },
    officeUrl() {
      if (process.env.officeURL) return process.env.officeURL;
      if (!window) return '';
      return `${window.location.origin}/office`;
    },
    isCashier() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_CASHER');
    },
    isCorporate() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_CORPORATE');
    },
    isAdmin() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_ADMIN');
    },
    isParseDocumentAllowed() {
      return this.isCashier || this.isAdmin;
    },
    userData() {
      return this.isUser ? this.user.model : {};
    },
    isBalanceLoaded() {
      return this.isUser && this.balance && typeof this.balance !== 'string';
    },
    motivationContractId() {
      return this.isUser ? Number(this.motivationContract) : null;
    },
    currentBonusStatus() {
      if (!this.isUser) return 'silver';
      if (PLATINUM_MOTIVATION_CONTRACT_IDS.includes(this.user.model.contractId)) return 'platinum';
      if (GOLD_MOTIVATION_CONTRACT_IDS.includes(this.user.model.contractId)) return 'gold';
      return 'silver';
    },
    isNewYear() {
      const DAYS_BEFORE_NY = 14;
      const DAYS_AFTER_NY = 7;

      const today = new Date();

      const before = new Date(today.getFullYear(), 11, 31 - DAYS_BEFORE_NY, 0, 0, 0);
      const after = new Date(today.getFullYear() + 1, 0, 1 + DAYS_AFTER_NY, 0, 0, 0);

      return today >= before && today <= after;
    },
  },
  mounted() {
    this.changeColorScheme();
  },
  methods: {
    changeColorScheme () {
      const root = document.getElementsByTagName('html')[0];
      root.className = this.currentTheme;
    },
    currencyFilter(value, currency) {
      return this.$options.filters.currencyFilter(value || 0, currency);
    },
    async shareCurrentUrl () {
      const origin = this.isUser ? 'https://agent.razlet.kg' : 'https://razlet.kg';
      await this.$shareUrl(origin + this.$route.fullPath);
    },
  },
});

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

const COUNTRIES = {
  RUB: 'ru',
  USD: 'en',
  'KGS': 'ru-KG',
  'KGS-kg': 'ru-KG',
  'KGS-ru': 'ru-KG',
  'KGS-en': 'ru-US',
};

const getKGCurrency = (currency) => `${currency}-${Vue.prototype.$locale}`;
const getCurrency = (currency) => currency === 'KGS' ? getKGCurrency(currency) : currency;

Vue.prototype.$localizeCurrency = (value, currency) => `${Vue.prototype.$localize.n(value, 'currency', COUNTRIES[getCurrency(currency)])}`;

const app = new Vue({});

const globalVars = {
  defaultSearch: 'FRU0000MOW1000es',
  showAgreement: process.env.type === 'public',
  newRequestsEnabled: process.env.type !== 'public',
  sendMetaMarkers: true,
  hideMultiCity: false,
  hideBonusCard: false,
  openError: {},
  isKg: true,
  transportTypes: ['avia'],
  showAirplanes: false,
};

Object.keys(globalVars).forEach(key => {
  app[key] = globalVars[key];

  if (typeof Vue.prototype[`$${key}`] === 'undefined') {
    Object.defineProperty(Vue.prototype, `$${key}`, {
      get() {
        return app[key];
      },
      set(value) {
        app[key] = value;
      },
    });
  }
});

Vue.prototype.$isNotNull = isNotNull;
Vue.prototype.$isFalsy = isFalsy;
Vue.prototype.$isNumber = isNumber;
Vue.prototype.$isString = isString;
Vue.prototype.$isObject = isObject;
Vue.prototype.$isValidObject = isValidObject;
Vue.prototype.$isArray = isArray;
Vue.prototype.$isValidArray = isValidArray;
